<template>
    <v-sheet style="margin-bottom: 15px">
        <!-- PC -->
        <v-sheet v-if="$vuetify.breakpoint.mdAndUp">
            <v-sheet>
                <v-sheet style="padding-top:140px;" width="1000" class="mx-auto">
                    <p class="text-center text-h4 font-weight-bold mb-16">회원정보 입력</p>
                </v-sheet>
                <v-sheet width="800" class="mx-auto">
                    <p class="mb-0 ml-2 text-body-2 red--text font-weight-bold">* 필수 입력 사항</p>
                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">아이디<font class="red--text">*</font></font>
                        <v-text-field
                            :error-messages="id_Errors"
                            class="pt-5 px-2"
                            placeholder="아이디를 입력해주세요"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            v-model="id"
                            @change="$v.id.$touch();"
                            @blur="$v.id.$touch()"
                        ></v-text-field>
                        <v-btn v-if="id_check === false"
                            @click="check()" style="width:140px;" class="mr-2" depressed dark color="#a87675">중복 확인</v-btn>
                      <v-btn v-else
                             disabled
                             style="width:140px;" class="mr-2" color="#a87675">확인완료</v-btn>
                    </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">비밀번호<font class="red--text">*</font></font>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="비밀번호를 입력해주세요"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            type="password"
                            v-model="password"
                            :error-messages="password_Errors"
                            @change="$v.password.$touch()"
                            @blur="$v.password.$touch()"
                        ></v-text-field>
                    </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">비밀번호 확인<font class="red--text">*</font></font>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="비밀번호 확인을 입력해주세요"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            type="password"
                            v-model="password_check"
                            :error-messages="password_check_Errors"
                            @change="$v.password_check.$touch()"
                            @blur="$v.password_check.$touch()"
                        ></v-text-field>
                    </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">담당자 이름<font class="red--text">*</font></font>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="담당자 이름을 입력해주세요"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            v-model="sellerName"
                            :error-messages="sellerName_Errors"
                            @change="$v.sellerName.$touch()"
                            @blur="$v.sellerName.$touch()"
                        ></v-text-field>
                    </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;" v-if="sellerCellPhone_check === false">
                        <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">담당자 전화번호<font class="red--text">*</font></font>

                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="담당자 전화번호를 입력해주세요"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            v-model="sellerCellPhone"
                            :error-messages="sellerCellPhone_Errors"
                            @change="$v.sellerCellPhone.$touch()"
                            @blur="$v.sellerCellPhone.$touch()"
                        ></v-text-field>
                        <v-btn
                            style="width:140px;" class="mr-2" depressed dark color="#a87675"
                            @click="authenticateCellPhone"
                        >인증번호 발송</v-btn>
                    </v-sheet>
                  <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;" v-else>
                    <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">담당자 전화번호<font class="red--text">*</font></font>

                    <v-text-field
                        class="pt-5 px-2"
                        placeholder="판매자 전화번호를 입력해주세요"
                        dense
                        color="brown"
                        style="background:#ececec !important;"
                        required
                        readonly
                        clearable
                        @click:clear="clearCellPhone"
                        v-model="sellerCellPhone"
                    ></v-text-field>
                    <v-btn
                        style="width:140px;" class="mr-2" color="primary"
                        disabled
                    >인증완료</v-btn>
                  </v-sheet>
                  <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                    <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">담당자 이메일<font class="red--text">*</font></font>
                    <v-text-field
                        class="pt-5 px-2"
                        placeholder="담당자 이메일을 입력해주세요"
                        dense
                        color="brown"
                        style="background:#ececec !important;"
                        required
                        v-model="email"
                        :error-messages="email_Errors"
                        @change="$v.email.$touch()"
                        @blur="$v.email.$touch()"
                    ></v-text-field>
                  </v-sheet>
                  <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                    <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">대표자 성함</font>
                    <v-text-field
                        class="pt-5 px-2"
                        placeholder="대표자 성함"
                        dense
                        color="brown"
                        style="background:#ececec !important;"
                        required
                        v-model="nameOfRepresentative"
                        :error-messages="nameOfRepresentative_Errors"
                        @change="$v.nameOfRepresentative.$touch()"
                        @blur="$v.nameOfRepresentative.$touch()"
                    ></v-text-field>
                  </v-sheet>
                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">대표 전화번호</font>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="대표 전화번호을 입력해주세요"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            v-model="representativePhone"
                            :error-messages="representativePhone_Errors"
                            @change="$v.representativePhone.$touch()"
                            @blur="$v.representativePhone.$touch()"
                        ></v-text-field>
                    </v-sheet>

                  <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                    <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">대표자 핸드폰번호</font>
                    <v-text-field
                        class="pt-5 px-2"
                        placeholder="대표자 핸드폰번호을 입력해주세요"
                        dense
                        color="brown"
                        style="background:#ececec !important;"
                        required
                        v-model="representativeCellPhone"
                        :error-messages="representativeCellPhone_Errors"
                        @change="$v.representativeCellPhone.$touch()"
                        @blur="$v.representativeCellPhone.$touch()"
                    ></v-text-field>
                  </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">사업자번호</font>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="사업자번호를 입력해주세요"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            v-model="businessRegistrationNumber"
                            :error-messages="businessRegistrationNumber_Errors"
                            @change="$v.businessRegistrationNumber.$touch()"
                            @blur="$v.businessRegistrationNumber.$touch()"
                        ></v-text-field>
<!--                        <v-btn style="width:140px;" class="mr-2" depressed dark color="#a87675">인증하기</v-btn>-->
                    </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center mt-6" style="background:#ececec !important;">
                        <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">상호명(법인명)</font>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="상호명을 입력해주세요"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            v-model="nameOfCompany"
                            :error-messages="nameOfCompany_Errors"
                            @change="$v.nameOfCompany.$touch()"
                            @blur="$v.nameOfCompany.$touch()"
                        ></v-text-field>
                    </v-sheet>
                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">사업자 구분</font>
                        <v-radio-group
                            class="mb-3 pa-0"
                            dense
                            row
                            
                            required
                            v-model="bizType"
                            :error-messages="bizType_Errors"
                            @change="$v.bizType.$touch()"
                            @blur="$v.bizType.$touch()"
                        >
                            <v-radio
                                color="#a87675"
                                dense
                                label="개인"
                                value="PB"
                            ></v-radio>
                            <v-radio
                                color="#a87675"
                                dense
                                label="법인"
                                value="CB"
                            ></v-radio>
                        </v-radio-group>
                    </v-sheet>

                    <v-sheet rounded class="pt-2 px-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">주소</font>
                        <v-text-field
                            class="pt-5 px-2"
                            readonly
                            clearable
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            placeholder="(우편번호) 주소"
                            :value="getAddress()"
                            @click:clear="clearAddress"
                        ></v-text-field>
                        <v-btn
                            v-if="existAddress === false"
                            style="width:140px;" class="mr-2" depressed dark color="#a87675" @click="showApi()">주소 찾기</v-btn>
                    </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center" style="background:#ececec !important; margin-top:-20px;">
                        <font class="font-weight-bold text-center" style="font-size:14px; width:180px;"></font>
                        <v-text-field
                            class="pt-5 px-2"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            v-model="detailAddress"
                            placeholder="상세 주소"
                            :error-messages="detailAddress_Errors"
                            @change="$v.detailAddress.$touch()"
                            @blur="$v.detailAddress.$touch()"
                        ></v-text-field>
                    </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <font class="font-weight-bold text-center" style="font-size:14px; width:180px;">업태 / 업종</font>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="업태"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            v-model="businessType"
                            :error-messages="businessType_Errors"
                            @change="$v.businessType.$touch()"
                            @blur="$v.businessType.$touch()"
                        ></v-text-field>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="업종"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            v-model="businessItemType"
                            :error-messages="businessItemType_Errors"
                            @change="$v.businessItemType.$touch()"
                            @blur="$v.businessItemType.$touch()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
                <v-sheet class="d-flex justify-center mt-10" style="margin-bottom:200px;">
                    <v-btn @click="submit()" :loading="submitting" to="/join/form" width="400" class="py-6" tile depressed dark color="#a87675">가입 완료</v-btn>
                </v-sheet>
            </v-sheet>
        </v-sheet>
        <!-- 모바일 -->
        <v-sheet v-if="!$vuetify.breakpoint.mdAndUp">
            <v-sheet class="px-2 mx-auto">
                <v-sheet class="my-16">
                    <p class="text-center text-h4 font-weight-bold mb-16">회원정보 입력</p>
                </v-sheet>
                <v-sheet class="mx-auto">
                    <p class="ml-2 text-caption red--text font-weight-bold">* 필수 입력 사항</p>
                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                      <v-sheet min-width="80" color="transparent" class="text-center">
                        <font class="font-weight-bold" style="font-size:10px;">아이디<font class="red--text">*</font></font>
                      </v-sheet>
                      <div class="d-flex flex-wrap align-center" style="width: 100%">
                        <v-text-field
                            :error-messages="id_Errors"
                            class="pt-5 px-2"
                            placeholder="아이디"
                            dense
                            color="brown"
                            style="background:#ececec !important;min-width: 200px;"
                            required
                            v-model="id"
                            @change="$v.id.$touch();"
                            @blur="$v.id.$touch()"
                        ></v-text-field>
                        <v-btn v-if="id_check === false"
                               style="height: 30px"
                               @click="check()" class="mx-2" depressed dark color="#a87675">중복확인</v-btn>
                        <v-btn v-else
                               disabled
                               style=";height: 30px" class="mx-2" color="#a87675">확인완료</v-btn>
                      </div>
                    </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <v-sheet min-width="80" color="transparent" class="text-center">
                            <font class="font-weight-bold" style="font-size:10px;">비밀번호<font class="red--text">*</font></font>
                        </v-sheet>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="비밀번호"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            type="password"
                            v-model="password"
                            :error-messages="password_Errors"
                            @change="$v.password.$touch()"
                            @blur="$v.password.$touch()"
                        ></v-text-field>
                    </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <v-sheet min-width="80" color="transparent" class="text-center">
                            <font class="font-weight-bold" style="font-size:10px;">비밀번호 확인<font class="red--text">*</font></font>
                        </v-sheet>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="비밀번호 확인"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            type="password"
                            v-model="password_check"
                            :error-messages="password_check_Errors"
                            @change="$v.password_check.$touch()"
                            @blur="$v.password_check.$touch()"
                        ></v-text-field>
                    </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <v-sheet min-width="80" color="transparent" class="text-center">
                            <font class="font-weight-bold" style="font-size:10px;">담당자 이름<font class="red--text">*</font></font>
                        </v-sheet>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="담당자 이름"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            v-model="sellerName"
                            :error-messages="sellerName_Errors"
                            @change="$v.sellerName.$touch()"
                            @blur="$v.sellerName.$touch()"
                        ></v-text-field>
                    </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;"  v-if="sellerCellPhone_check === false">
                      <v-sheet min-width="80" color="transparent" class="text-center">
                          <font class="font-weight-bold" style="font-size:10px;">담당자 전화번호<font class="red--text">*</font></font>
                      </v-sheet>
                      <div class="d-flex flex-wrap align-center" style="width: 100%">
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="담당자 전화번호"
                            dense
                            color="brown"
                            style="background:#ececec !important;min-width: 200px;"
                            required
                            v-model="sellerCellPhone"
                            :error-messages="sellerCellPhone_Errors"
                            @change="$v.sellerCellPhone.$touch()"
                            @blur="$v.sellerCellPhone.$touch()"
                        ></v-text-field>
                        <v-btn
                            @click="authenticateCellPhone"
                            style="height: 30px"
                            class="mx-2" depressed dark color="#a87675">인증하기</v-btn>
                      </div>
                    </v-sheet>
                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;"  v-else>
                      <v-sheet min-width="80" color="transparent" class="text-center">
                        <font class="font-weight-bold" style="font-size:10px;">담당자 전화번호<font class="red--text">*</font></font>
                      </v-sheet>
                      <div class="d-flex flex-wrap align-center" style="width: 100%">
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="판매자 전화번호"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            readonly
                            clearable
                            @click:clear="clearCellPhone"
                            v-model="sellerCellPhone"
                        ></v-text-field>
                        <v-btn
                            style="height: 30px"
                            class="mx-2" color="primary"
                            disabled
                        >인증완료</v-btn>
                      </div>
                    </v-sheet>
                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                    <v-sheet min-width="80" color="transparent" class="text-center">
                      <font class="font-weight-bold" style="font-size:10px;">담당자 이메일<font class="red--text">*</font></font>
                    </v-sheet>
                    <v-text-field
                        class="pt-5 px-2"
                        placeholder="담당자 이메일"
                        dense
                        color="brown"
                        style="background:#ececec !important;"
                        required
                        v-model="email"
                        :error-messages="email_Errors"
                        @change="$v.email.$touch()"
                        @blur="$v.email.$touch()"
                    ></v-text-field>
                  </v-sheet>
                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                    <v-sheet min-width="80" color="transparent" class="text-center">
                      <font class="font-weight-bold" style="font-size:10px;">대표자 성함</font>
                    </v-sheet>
                    <v-text-field
                        class="pt-5 px-2"
                        placeholder="대표자 성함"
                        dense
                        color="brown"
                        style="background:#ececec !important;"
                        required
                        v-model="nameOfRepresentative"
                        :error-messages="nameOfRepresentative_Errors"
                        @change="$v.nameOfRepresentative.$touch()"
                        @blur="$v.nameOfRepresentative.$touch()"
                    ></v-text-field>
                  </v-sheet>
                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <v-sheet min-width="80" color="transparent" class="text-center">
                            <font class="font-weight-bold" style="font-size:10px;">대표 전화번호</font>
                        </v-sheet>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="대표 전화번호"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            v-model="representativePhone"
                            :error-messages="representativePhone_Errors"
                            @change="$v.representativePhone.$touch()"
                            @blur="$v.representativePhone.$touch()"
                        ></v-text-field>
                    </v-sheet>
                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                      <v-sheet min-width="80" color="transparent" class="text-center">
                        <font class="font-weight-bold" style="font-size:10px;">대표자 핸드폰번호</font>
                      </v-sheet>
                      <v-text-field
                          class="pt-5 px-2"
                          placeholder="대표자 핸드폰번호"
                          dense
                          color="brown"
                          style="background:#ececec !important;"
                          required
                          v-model="representativeCellPhone"
                          :error-messages="representativeCellPhone_Errors"
                          @change="$v.representativeCellPhone.$touch()"
                          @blur="$v.representativeCellPhone.$touch()"
                      ></v-text-field>
                    </v-sheet>
                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <v-sheet min-width="80" color="transparent" class="text-center">
                            <font class="font-weight-bold" style="font-size:10px;">사업자번호</font>
                        </v-sheet>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="사업자번호"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            v-model="businessRegistrationNumber"
                            :error-messages="businessRegistrationNumber_Errors"
                            @change="$v.businessRegistrationNumber.$touch()"
                            @blur="$v.businessRegistrationNumber.$touch()"
                        ></v-text-field>
<!--                        <v-btn class="mr-2" depressed dark color="#a87675">인증하기</v-btn>-->
                    </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center mt-6" style="background:#ececec !important;">
                        <v-sheet min-width="80" color="transparent" class="text-center">
                            <font class="font-weight-bold" style="font-size:10px;">상호명(법인명)</font>
                        </v-sheet>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="상호명"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            v-model="nameOfCompany"
                            :error-messages="nameOfCompany_Errors"
                            @change="$v.nameOfCompany.$touch()"
                            @blur="$v.nameOfCompany.$touch()"
                        ></v-text-field>
                    </v-sheet>
                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <v-sheet min-width="80" color="transparent" class="text-center">
                            <font class="font-weight-bold" style="font-size:10px;">사업자 구분</font>
                        </v-sheet>
                        <v-radio-group
                            class="ml-4"
                            dense
                            row
                            required
                            v-model="bizType"
                            :error-messages="bizType_Errors"
                            @change="$v.bizType.$touch()"
                            @blur="$v.bizType.$touch()"
                        >
                            <v-radio
                                color="#a87675"
                                dense
                                label="개인"
                                value="PB"
                            ></v-radio>
                            <v-radio
                                color="#a87675"
                                dense
                                label="법인"
                                value="CB"
                            ></v-radio>
                        </v-radio-group>
                    </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <v-sheet min-width="80" color="transparent" class="text-center">
                            <font class="font-weight-bold" style="font-size:10px;">주소</font>
                        </v-sheet>
                        <v-text-field
                            class="pt-5 px-2"
                            readonly
                            clearable
                            dense
                            placeholder="우편번호"
                            color="brown"
                            style="background:#ececec !important;"
                            :value="postCode"
                            @click:clear="clearAddress"
                        ></v-text-field>
                        <v-btn
                            style="height: 30px"
                            class="mx-2" depressed dark color="#a87675" @click="showApi()"
                        >
                          주소찾기
                        </v-btn>
                    </v-sheet>

                  <v-sheet rounded class="pa-2 d-flex align-center" style="background:#ececec !important;margin-top:-16px;">
                    <v-sheet min-width="80" color="transparent" class="text-center">
                      <font class="font-weight-bold" style="font-size:10px;"></font>
                    </v-sheet>
                    <v-text-field
                        class="pt-5 px-2"
                        readonly
                        dense
                        placeholder="주소"
                        color="brown"
                        style="background:#ececec !important;"
                        :value="address"
                    ></v-text-field>
                  </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center" style="background:#ececec !important; margin-top:-16px;">
                        <v-sheet min-width="80" color="transparent" class="text-center">
                            <font class="font-weight-bold" style="font-size:10px;"></font>
                        </v-sheet>
                        <v-text-field
                            class="pt-5 px-2"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            placeholder="상세 주소"
                            required
                            v-model="detailAddress"
                            :error-messages="detailAddress_Errors"
                            @change="$v.detailAddress.$touch()"
                            @blur="$v.detailAddress.$touch()"
                        ></v-text-field>
                    </v-sheet>

                    <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                        <v-sheet min-width="80" color="transparent" class="text-center">
                            <font class="font-weight-bold" style="font-size:10px;">업태 / 업종</font>
                        </v-sheet>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="업태"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            v-model="businessType"
                            :error-messages="businessType_Errors"
                            @change="$v.businessType.$touch()"
                            @blur="$v.businessType.$touch()"
                        ></v-text-field>
                        <v-text-field
                            class="pt-5 px-2"
                            placeholder="업종"
                            dense
                            color="brown"
                            style="background:#ececec !important;"
                            required
                            v-model="businessItemType"
                            :error-messages="businessItemType_Errors"
                            @change="$v.businessItemType.$touch()"
                            @blur="$v.businessItemType.$touch()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
                <v-sheet class="d-flex justify-center mt-8 mb-16 mx-2">
                    <v-btn @click="submit()"
                           to="/join/form" block class="py-6" tile depressed dark color="#a87675" :loading="submitting">가입 완료</v-btn>
                </v-sheet>
            </v-sheet>
        </v-sheet>
      <SellerCellphone
          ref="sellerCellphone"
      />
    </v-sheet>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import {isEmpty} from "@/lib/CommUtil";
import SellerCellphone from './validate/SellerCellphone';

export default {
    components:{
      SellerCellphone
    },
    mixins: [validationMixin],

    validations: {
        id: {
            required:function (){
              if(isEmpty(this.id)){
                return false
              }
              if(this.id_check === true){
                if(this.id === this.checked_id){
                  return true
                }
                this.id_check=false
                return false
              }
              return false
            },
            minLength: minLength(4),
            maxLength: maxLength(32),
        },
        password: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(48),
        },
        password_check: {
            required,
            sameAsPassword: sameAs('password'),
        },
        sellerName: {
            required,
            maxLength: maxLength(20),
        },
        sellerCellPhone: {
            required,
            numeric,
            minLength: minLength(8),
            maxLength: maxLength(20),
        },
        representativePhone: {
            // required,
            numeric
        },
        representativeCellPhone: {
            // required,
           numeric
        },
        businessRegistrationNumber: {
            // required,
            maxLength: maxLength(10),
            numeric
        },
        nameOfCompany:{
            // required,
        },
        nameOfRepresentative:{
            // required,
        },
        email:{
            required,
            email
        },
        bizType:{
            // required,
        },
        detailAddress:{
          required: function (){
            if(isEmpty(this.postCode)){
              return true
            }
            if(isEmpty(this.detailAddress)){
              return false
            }
            return true
          }
        },
        businessType:{
            // required,
        },
        businessItemType:{
            // required,
        }
    },

    data: () => ({
      submitting:false,
      id: null,
      password: null,
      sellerName: null,
      sellerCellPhone: null,
      representativePhone: null,
      representativeCellPhone: null,
      businessRegistrationNumber: null,
      nameOfCompany: null,
      nameOfRepresentative: null,
      email: null,
      bizType: null,
      postCode:null,
      address:null,
      detailAddress: null,
      businessItemType: null,
      businessType: null,

      id_check:false,
      checked_id:null,
      password_check:null,
      sellerCellPhone_check:false,
      businessRegistrationNumber_check:null,

      submitStatus:"",
      width:500,
      height:600,
    }),

    computed: {
        id_Errors () {
            const errors = []
            if (!this.$v.id.$dirty) return errors
            !this.$v.id.required && errors.push('아이디를 입력 및 중복 체크를 해주세요')
            !this.$v.id.minLength && errors.push('아이디는 최소 4자 이상이여야합니다')
            !this.$v.id.maxLength && errors.push('아이디는 최대 32자 이내이여야합니다')
            return errors
        },
        password_Errors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('패스워드를 입력해주세요')
            !this.$v.password.minLength && errors.push('패스워드는 최소 8자 이상이여야합니다')
            !this.$v.password.maxLength && errors.push('패스워드는 최대 48자 이내이여야합니다')
            return errors
        },
        password_check_Errors () {
            const errors = []
            if (!this.$v.password_check.$dirty) return errors
            !this.$v.password_check.required && errors.push('패스워드 확인을 입력해주세요')
            !this.$v.password_check.sameAsPassword && errors.push('패스워드 확인이 같지 않습니다')
            return errors
        },
        sellerName_Errors () {
            const errors = []
            if (!this.$v.sellerName.$dirty) return errors
            !this.$v.sellerName.required && errors.push('판매자 이름을 입력해주세요')
            !this.$v.sellerName.maxLength && errors.push('판매자 이름은 최대 20자 이내이여야합니다')
            return errors
        },
        sellerCellPhone_Errors () {
            const errors = []
            if (!this.$v.sellerCellPhone.$dirty) return errors
            !this.$v.sellerCellPhone.required && errors.push('판매자 전화번호를 입력해주세요')
            !this.$v.sellerCellPhone.numeric && errors.push('숫자만 입력해주세요')
            return errors
        },
        representativePhone_Errors () {
            const errors = []
            if (!this.$v.representativePhone.$dirty) return errors
            // !this.$v.representativePhone.required && errors.push('대표자 전화번호를 입력해주세요')
            !this.$v.representativePhone.numeric && errors.push('숫자만 입력해주세요')
            return errors
        },
      representativeCellPhone_Errors () {
        const errors = []
        if (!this.$v.representativeCellPhone.$dirty) return errors
        // !this.$v.representativePhone.required && errors.push('대표자 전화번호를 입력해주세요')
        !this.$v.representativeCellPhone.numeric && errors.push('숫자만 입력해주세요')
        return errors
      },
        businessRegistrationNumber_Errors () {
            const errors = []
            if (!this.$v.businessRegistrationNumber.$dirty) return errors
            // !this.$v.businessRegistrationNumber.required && errors.push('사업자번호를 입력해주세요')
            !this.$v.businessRegistrationNumber.maxLength && errors.push('사업자번호는 최대 10자 입니다')
            !this.$v.businessRegistrationNumber.numeric && errors.push('숫자만 입력해주세요')
            return errors
        },
        // nameOfCompany_Errors () {
        //     const errors = []
        //     if (!this.$v.nameOfCompany.$dirty) return errors
        //     !this.$v.nameOfCompany.required && errors.push('상호명(법인명)을 입력해주세요')
        //     return errors
        // },
        // nameOfRepresentative_Errors () {
        //     const errors = []
        //     if (!this.$v.nameOfRepresentative.$dirty) return errors
        //     !this.$v.nameOfRepresentative.required && errors.push('대표자 성함을 입력해주세요')
        //     return errors
        // },
        email_Errors () {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.required && errors.push('이메일을 입력해주세요')
            !this.$v.email.email && errors.push('이메일 형식으로 입력해주세요')
            return errors
        },
        // bizType_Errors () {
        //     const errors = []
        //     if (!this.$v.bizType.$dirty) return errors
        //     !this.$v.bizType.required && errors.push('사업자 분류를 선택하셔야 가입하실 수 있습니다.')
        //     return errors
        // },
        detailAddress_Errors () {
          console.log('detailAddress_Errors')
          const errors = []
          if(isEmpty(this.postCode)){
            return errors
          }
          if (!this.$v.detailAddress.$dirty) return errors
          !this.$v.detailAddress.required && errors.push('상세주소를 입력해주세요')
          return errors
        },
        // businessItemType_Errors () {
        //     const errors = []
        //     if (!this.$v.businessItemType.$dirty) return errors
        //     !this.$v.businessItemType.required && errors.push('업종을 입력해주세요')
        //     return errors
        // },
        // businessType_Errors () {
        //     const errors = []
        //     if (!this.$v.businessType.$dirty) return errors
        //     !this.$v.businessType.required && errors.push('업태를 입력해주세요')
        //     return errors
        // }
      existAddress(){
          if(isEmpty(this.postCode)){
            return false
          }
          return true
      },
    },

    methods: {
      getAddress(){
        if(isEmpty(this.postCode)){
          return null
        }
        return '(' + this.postCode + ') ' + this.address
      },
      check(){
        const regType1 = /^[a-z|A-Z|0-9|]+$/;
        if (!regType1.test(this.id)) {
          alert('아이디는 영문,숫자 포함해서 4~32자리 이내로 입력해주세요. 공백, 특수 문자(!@#$%^&*... 등등)와 한글은 입력이 불가합니다.');
          return
        }
        const DOMAIN = process.env.VUE_APP_API_HOST
        this.$http.post(DOMAIN + "/api/v1/seller/validate/ids/"+this.id, {
            }).then((res) => {
                if(res.data.data){
                  alert("같은 아이디가 이미 존재합니다.")
                }else{
                  const config = {
                    headers: {
                      'Content-Type': 'text/plain'
                    }
                  };
                  this.$http.post(DOMAIN + "/auth/api/v1/accounts/member/validate/id", this.id,config).then( res =>{
                    console.log(res);
                    if(res.data.resCode ==='0000' && res.data.data.found === false ){
                      alert("중복 확인이 되었습니다.")
                      this.id_check=true;
                      this.checked_id = this.id
                    }else{
                      alert("같은 아이디가 이미 존재합니다.")
                    }
                  }).catch((ex)=>{
                    console.error(ex);
                    alert("아이디 검증 중 오류가 발생했습니다.")
                  })
                }
            }).catch( (ex) => {
              const response = ex.response
              console.log('validate res',response)
              const res = response.data
              if(res.resCode === '0109' || res.resCode ==='0101'){
                alert(res.data)
              } else{
                alert(res.msg)
              }
            })
      },
      reset_check(){
          console.log("reset_check");
          this.id_check=false;
      },
      showApi() {
          new window.daum.Postcode({
              oncomplete: (data) => {
              // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
              // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
              // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
              let fullRoadAddr = data.roadAddress;
              // 도로명 주소 변수
              let extraRoadAddr = '';
              // 도로명 조합형 주소 변수
              // 법정동명이 있을 경우 추가한다. (법정리는 제외)
              // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
              if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){ extraRoadAddr += data.bname; }
              // 건물명이 있고, 공동주택일 경우 추가한다.
              if(data.buildingName !== '' && data.apartment === 'Y'){ extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName); }
              // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
              if(extraRoadAddr !== ''){ extraRoadAddr = ' (' + extraRoadAddr + ')'; }
              // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
              if(fullRoadAddr !== ''){ fullRoadAddr += extraRoadAddr; }
              // 우편번호와 주소 정보를 해당 필드에 넣는다.
              this.postCode = data.zonecode;
              this.address = fullRoadAddr;
          }
          // }).embed(this.$refs.embed)
          }).open({
            left: (window.screen.width / 2) - (this.width / 2),
            top: (window.screen.height / 2) - (this.height / 2)
          })
      },
      submit(){
          let join={
              id: this.id,
              password: this.password,
              sellerName: this.sellerName,
              sellerCellPhone: this.sellerCellPhone,
              representativePhone: this.representativePhone,
              representativeCellPhone: this.representativeCellPhone,
              businessRegistrationNumber: this.businessRegistrationNumber,
              nameOfCompany: this.nameOfCompany,
              nameOfRepresentative: this.nameOfRepresentative,
              email: this.email,
              bizType: this.bizType,
              address: {
                  address: this.address,
                  postCode: this.postCode,
                  detailAddress: this.detailAddress
              },
              businessItemType: this.businessItemType,
              businessType: this.businessType
          }

          const DOMAIN = process.env.VUE_APP_API_HOST

          console.log(join);
          if(isEmpty(this.postCode)){
            join.address=null
          }

          this.$v.$touch()

          if (this.$v.$invalid) {
            this.submitStatus = 'ERROR'
            alert('비어있는 입력란이 있거나 잘못 입력된 항목이 있습니다.')
            return
          }

          if(!this.id_check){
            alert('아이디 중복확인이 필요합니다.')
            return
          }
          const mode = process.env.VUE_APP_MODE
          if(mode === 'PROD'){
            if(isEmpty(this.sellerCellPhone_check)){
              alert('휴대폰 인증이 필요합니다.')
              return
            }
          }
          /*if(isEmpty(this.businessRegistrationNumber_check)){
            alert('사업자번호 인증이 필요합니다.')
            return
          }*/
        console.log('submitting')
        this.submitting=true
          this.$http.post(DOMAIN + "/api/v1/seller/register", join).then((res) => {
            this.submitting=false
              console.log(res);
              if(res.data.data.code==='00'){
                  this.$router.push('/join/welcome')
              }else{
                  alert('오류코드: '+res.data.data.errCode+'\n메세지:'+res.data.data.errMessage)
              }
          }).catch(ex =>{
            const res = ex.response
            console.error("가입오류",res)
            this.submitting=false
            if(res.data.resCode ==='0101' || res.data.resCode ==='0109'){
              alert(res.data.data)
            }else{
              alert('요청 처리중 오류가 발생했습니다.')
            }
          })
          this.submitStatus = 'PENDING'
          setTimeout(() => {
              this.submitStatus = 'OK'
          }, 500)
      },
      clearAddress(){
        this.address= null
        this.postCode= null
        this.detailAddress= null
      },
      authenticateCellPhone(){
        this.$v.$touch()

        if (this.$v.sellerCellPhone.$invalid) {
          alert('휴대폰 번호를 입력해주세요.')
          return
        }
        this.$refs.sellerCellphone.open(this.sellerCellPhone,(ret) =>{
          if(ret === true){
            this.sellerCellPhone_check = true
            this.$refs.sellerCellphone.close()
            alert('인증완료되었습니다.')
          }else{
            this.sellerCellPhone_check = false
            alert('인증이 실패하였습니다.')
          }
        })
      },
      clearCellPhone(){
        this.sellerCellPhone=''
        this.sellerCellPhone_check=false
      },
    }
}
</script>